<script setup lang="ts">
import dayjs from "dayjs";

const props = defineProps<{
	prizePool: number;
	isCompleted?: string;
	finishedAt?: string;
}>();

const prepareImgUrl = useImage();
const { t } = useT();
const { durationLeft, reset } = useCountdown({
	timestamp: props.finishedAt || "",
	formatToken: dayjs(props.finishedAt).diff(dayjs(), "d") >= 1 ? "DD[d ]HH[h ]mm[m ]" : "HH[h ]mm[m ]ss[s ]"
});

const emit = defineEmits<{ (event: "click"): void }>();

const btnText = computed(() =>
	props.isCompleted ? t("freeSpin.activity.btn.name2") : t("freeSpin.activity.btn.name")
);

const handleClick = () => {
	if (props.isCompleted) {
		return;
	}
	emit("click");
};

watch(
	() => props.finishedAt,
	() => {
		reset(props.finishedAt || "");
	}
);
</script>

<template>
	<div class="activity-free-spin" @click="handleClick">
		<picture>
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/free-spin-quest/activity/img-mob.webp', {
						format: 'avif',
						width: 320,
						height: 216
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg
				class="image"
				src="/nuxt-img/free-spin-quest/activity/img-desk.webp"
				format="avif"
				width="326"
				height="340"
				alt="bingo"
				loading="lazy"
			/>
		</picture>
		<ABadge variant="skew" color="var(--chomutov)" background="var(--chusovoy)" autosize>
			<AText :size="12" :modifiers="['bold', 'uppercase']">
				{{ t("freeSpin.activity.title") }}
			</AText>
		</ABadge>
		<div class="content">
			<div class="prize-wrapper">
				<AText class="label text-cannes" :size="12" :modifiers="['bold', 'uppercase']">
					<i18n-t keypath="freeSpin.activity.label.name">
						<template #br>
							<br />
						</template>
					</i18n-t>
				</AText>
				<MPrizeFund variant="free-spin" :iconSize="28" img="/nuxt-img/free-spin-quest/fs.png">
					<AText :size="20" :modifiers="['bold']" class="prize-value">
						{{ numberFormat(prizePool ?? 0) }}
					</AText>
				</MPrizeFund>
			</div>
			<AText :size="14" class="text-conakry">
				<i18n-t keypath="freeSpin.activity.timer">
					<template #timer>
						<AText :size="14" class="text-cannes" :modifiers="['bold']">{{ durationLeft }}</AText>
					</template>
				</i18n-t>
			</AText>
		</div>
		<AButton variant="primary" :modifiers="['wide', isCompleted && 'disabled']">
			<AText :size="16" :modifiers="['uppercase', 'semibold']">
				{{ btnText }}
			</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.activity-free-spin.item {
	justify-content: space-between;

	@include media-breakpoint-down(md) {
		padding: 112px 0 12px;
		align-items: center;
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
	}

	.prize-wrapper {
		display: flex;

		@include media-breakpoint-down(md) {
			align-items: center;
			flex-direction: column;
		}
	}

	.prize-value {
		color: var(--chusovoy);
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 4px;

		@include media-breakpoint-down(md) {
			justify-content: center;
		}
	}

	.label {
		margin-right: 4px;

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-right: 0;

			br {
				display: none;
			}
		}
	}

	button {
		max-width: 165px;

		@include media-breakpoint-down(md) {
			max-width: 135px;

			span {
				font-size: 14px;
			}
		}
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		width: 163px;
		height: 170px;

		@include media-breakpoint-down(md) {
			width: 175px;
			left: 50%;
			transform: translateX(-50%);
			height: auto;
		}
	}
}
</style>
